.nav-main {
    background-color: var(--bg);
    color: var(--text_primary);
    position: sticky;
    top: 0;
    z-index: 100;
}

.nav-container {
    max-width: 1100px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
}
.portfilio-logo {
    font-size: 2.5rem;
}
.nav-logo a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-logo a span {
    font-size: 1.5rem;
    font-weight: 600;
}
.nav-container a {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text_primary);
    text-decoration: none;
}

.nav-link {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
}

.nav-link :hover {
    color: var(--primary);
    transition: all 0.5s ease;
}

#github-button {
    border: 2px solid var(--primary);
    color: var(--primary);
    padding: 0.6rem 0.7rem;
    border-radius: 25px;
    font-size: 1rem;
    color: var(--primary);
}

#github-button :hover {
    color: green;
}


@media screen and (max-width: 768px) {
    .nav-container {
        width: 100%;
    }
    .nav-link {
        display: none;
    }

}








@media (max-width: 1100px) {
    .nav-container {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .nav-link {
        flex-direction: column;
        background-color: var(--bg);
        /* position: absolute; */
    }
}