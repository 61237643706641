.project-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(74, 74, 74, 0.2);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background-color: var(--card_light);
    max-width: 768px;
    margin: 0 auto;
    color: var(--text_secondary);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.modal-heading {
    display: flex;
    justify-content: space-between;
}

.modal-heading h2 {
    color: white;
    color: var(--primary);
}

.modal-project-point {
    display: flex;
    margin: 0.8rem 0;
    text-align: left;
}

.login-info {
    padding: 0.5rem 1rem;
    background-color: black;
    border-radius: 12px;
}

.login-info h4 {
    color: white;
    text-decoration: underline;
}

#modal-table {
    width: 100%;
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 18px;
    text-align: left;
    table-layout: auto;
}

#modal-table td:first-child {
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    width: 1%;
}

#modal-table td:last-child {
    text-align: left;
    color: white;
    padding-left: 5px;
    font-weight: 500;
}

#modal-table tr td {
    border: none;
}

.modal-heading button {
    font-size: 2rem;
    cursor: pointer;
    background-color: white;
    border: none;
    display: flex;
    color: black;
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

