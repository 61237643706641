.footer {
    /* clip-path: polygon(15% 0, 100% 5%, 100% 100%, 0 100%, 0 4%); */
    clip-path: polygon(23% 0, 100% 9%, 100% 100%, 0 100%, 0 8%);
    background-color: var(--bg);
    margin-top: -5rem;
    padding: 3rem 0;
}
.footer-container {
    max-width: 1100px;
    margin: 0 auto;
}
.footer-heading {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: var(--primary);
}
.footer-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 1.5rem 0;
}
.footer-link {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 450;
    line-height: 2rem;
    color: var(--text_primary);
}
.footer-link:hover {
    color: var(--primary);
}

.footer-social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
}
.footer-social-container a {
    color: var(--text_primary);
    font-size: 1.5rem;
}
.footer-social-container a:hover{
    color: var(--primary);
}

.footer-copyright {
    text-align: center;
    color: var(--text_primary);
    font-size: 0.9rem;
    margin: 2rem 0;
}