#projects {
    clip-path: polygon(22% 0, 100% 9%, 100% 100%, 0 100%, 0 9%);
    margin-top: -20rem;
    padding-top: 10rem;
    padding-bottom: 5rem;
    background-color: var(--bg);
}

#projects h2 {
    color: var(--text_primary);
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
}

.porject-head-desc {
    text-align: center;
    color: var(--text_secondary);
    margin: 1rem auto;
    width: 50%;
    font-size: 1.1rem;
}

.project-container {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.project-card {
    max-width: 45%;
    margin: 1rem 0;
    color: white;
    padding: 1.5rem;
    border-radius: 10px;
    background-color: var(--card);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.project-image {
    height: 200px;
    width: 100%;
    border-radius: 8px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
}

.project-tag {
    font-size: 1rem;
    border-radius: 8px;
    padding: 4px;
    color: var(--primary);
    background-color: rgba(133, 76, 230,0.1);
    margin: 0.3rem 0.2rem;
}

.project-title {
    font-size: 1.5rem;
    color: var(--text_primary);
}

.project-desc {
    color: var(--text_secondary);
    font-size: 1rem;
    text-align: left;
}

.project-btn-container {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.project-btn {
    cursor: pointer;
    font-size: 1.1rem;
    border: none;
    padding: 0.5rem 3rem;
    border-radius: 10px;
    font-weight: 500;
}

.live-btn {
    background-color: var(--primary);
    color: var(--text_primary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.git-btn {
    background-color: var(--card_light);
    color: var(--text_primary);
    border: 1px solid var(--primary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.git-btn :hover {
    background-color: var(--primary);

}

@media (max-width:1100px) {
    .project-container {
        max-width: 90%;
    }

    .project-card {
        max-width: 49%;
    }

    .project-btn {
        font-size: 1rem;
        padding: 0.3rem 1rem;
    }
}

@media (max-width:768px) {
    #projects {
        /* clip-path: polygon(12% 0, 100% 4%, 100% 100%, 0 97%, 0 4%); */
        clip-path: polygon(14% 0, 100% 3%, 100% 100%, 0 100%, 0 3%);
    }
    .project-container{
        flex-direction: column;
        max-width: 90%;
    }
    .project-card {
        max-width: 100%;
    }
    .porject-head-desc{
        width: 90%;
    }
    .project-btn {
        font-size: 1rem;
        padding: 0.3rem 0.5rem;
    }
    
}